<style lang="scss" scoped>
.money {
	margin-top: 10vh;
	font-size: 0;
	text-align: center;
	font-weight: bold;
	color: #052433;
	.icon {
		font-size: 0.26rem;
	}
	.text {
		font-size: 0.6rem;
	}
}
.btn {
	padding: 1rem 0.4rem;
	font-weight: bold;
}
.pay_success_page {
	.icon {
		width: 1.45rem;
		height: 1.45rem;
		margin: 1.6rem auto 0.5rem;
		background: url(../assets/icon/success.png);
		background-repeat: no-repeat;
		background-size: 100%;
		background-position: center;
	}
	.text {
		font-size: 0.3rem;
		color: #052433;
		line-height: 0.3rem;
		font-weight: bold;
		text-align: center;
	}
}
.pay_error_page {
	.text {
		margin: 1.6rem auto 0.5rem;
		font-size: 0.3rem;
		color: #052433;
		line-height: 0.3rem;
		font-weight: bold;
		text-align: center;
	}
}

.update_time {
	padding: 0.3rem;
	box-sizing: border-box;
	font-size: 0.3rem;
	.title {
		font-size: 0.3rem;
		line-height: 0.6rem;
	}
	.label {
		font-size: 0.28rem;
		line-height: 0.5rem;
	}
	.value {
		font-size: 0.28rem;
		color: $font_color_info;
		line-height: 0.5rem;
	}
	.err_msg {
		margin-top: 0.4rem;
		margin-bottom: 0.4rem;
		font-size: 0.36rem;
		font-weight: bold;
	}
	.btns {
		margin-top: 0.6rem;
		display: flex;
		justify-content: space-around;
		.van_btn {
			width: 40%;
		}
	}
}

.disabled_pay_info {
	margin: 10vh 0.3rem 0;
	font-size: 0.4rem;
	text-align: center;
}

.weChat_warning {
	font-size: 0.4rem;
	text-align: center;
}
</style>

<template>
	<div>
		<div class="weChat_warning" v-if="weChatWarningShow">
			<h4>请在微信中打开</h4>
		</div>
		<template v-else>
			<div v-if="payPage">
				<div class="money">
					<span class="icon">￥</span>
					<span class="text" v-text="money"></span>
				</div>
				<div class="btn">
					<van-button type="primary" :loading="loading" block @click="pay">支付</van-button>
				</div>
			</div>
			<div v-if="successPage" class="pay_success_page">
				<div class="icon"></div>
				<div class="text">支付成功</div>
			</div>
			<div v-if="errorPage" class="pay_error_page">
				<div class="text">该订单已失效</div>
			</div>

			<!-- 更新保障时间 -->
			<div v-if="updatePage" class="update_time">
				<div class="order_info">
					<div class="title" v-text="billGoodsName"></div>
					<div class="row">
						<span class="label">起保时间：</span>
						<span class="value" v-text="billInfo.enableDate"></span>
					</div>
					<div class="row">
						<span class="label">终保时间：</span>
						<span class="value" v-text="billInfo.disEnableDate"></span>
					</div>
					<div class="row">
						<span class="label">被保险人：</span>
						<span class="value" v-text="assured"></span>
					</div>
				</div>
				<div class="err_msg">该订单已超过最晚支付时间，是否更新保障期限为以下时间继续支付</div>
				<div class="row">
					<span class="label">起保时间：</span>
					<span class="value" v-text="updateTime.start"></span>
				</div>
				<div class="row">
					<span class="label">终保时间：</span>
					<span class="value" v-text="updateTime.end"></span>
				</div>
				<div class="btns">
					<van-button class="van_btn" type="primary" @click="submitUpdateTime">更新</van-button>
					<van-button class="van_btn" type="default" @click="weixin_closePage">关闭</van-button>
				</div>
			</div>

			<!-- 无法支付提示 -->
			<div class="disabled_pay_info" v-if="disabledPayPage" v-text="disabledPayText"></div>
		</template>
	</div>
</template>

<script>
import { Button } from 'vant';
import { loginNoPwd, getWxInfo, getPayInfo, getWxJSAPIPay, getWxJSAPIRechargePay, getOpenId, getBillsInfo, updateInsureRange } from '@/request/api';
import { http_getServerTime, http_aLiPay, http_getRechargePayInfo,http_getRechargeForm } from '@/request/common';

export default {
	name: 'pay', // 支付聚合页面
	components: {
		[Button.name]: Button,
	},
	data() {
		return {
			env: undefined, // 系统环境
			isRecharge: false, // 是否为充值类型
			weChatWarningShow: false,
			payPage: false,
			successPage: false,
			errorPage: false,
			money: 0.0,
			billId: undefined,
			rechargeId: undefined,
			openId: undefined,
			loading: false,

			updatePage: false,
			billGoodsName: '',
			billInfo: {
				enableDate: '',
				disEnableDate: '',
			},
			assured: '',
			updateTime: {
				start: '',
				end: '',
			},

			disabledPayText: '',
			disabledPayPage: false,
		};
	},
	watch: {
		$route: {
			handler: function (val) {
				let params = val.query;
				this.billId = params.id;
				this.rechargeId = params.rechargeId;

				this.env = this.$base.getEnv();

				// 判断是否为充值业务
				if (Object.hasOwnProperty.call(params, 'rechargeId')) {
					this.isRecharge = true;

					// 充值只提供了微信支付，所以这里处理一下
					// if (this.env !== 'wx') {
					// 	this.weChatWarningShow = true;
					// 	return;
					// }
				} else {
					this.isRecharge = false;
				}

				// 登录
				this.login().then(() => {
					if (Object.hasOwnProperty.call(params, 'id')) {
						// 投保支付
						// 检测订单是否可以支付
						this.checkOrder(params.id).then(() => {
							// 获取订单信息
							this.getBillPayInfo(params.id).then(() => {
								// 进入支付流程
								this.checkEnvironment(Object.hasOwnProperty.call(params, 'code'));
							});
						});
					} else if (this.isRecharge) {
						// 充值支付
						// 重定向回来了，再获取支付金额，否则页面会闪一下
						this.getRechargeBillPayInfo(params.rechargeId).then(() => {
							// 进入支付流程
							this.checkEnvironment(Object.hasOwnProperty.call(params, 'code'));
						});
					}
				});
			},
			immediate: true,
		},
	},
	created() {},
	methods: {
		weixinClosePage() {
			if (typeof WeixinJSBridge == 'undefined') {
				if (document.addEventListener) {
					document.addEventListener('WeixinJSBridgeReady', weixin_ClosePage, false);
				} else if (document.attachEvent) {
					document.attachEvent('WeixinJSBridgeReady', weixin_ClosePage);
					document.attachEvent('onWeixinJSBridgeReady', weixin_ClosePage);
				}
			} else {
				weixin_ClosePage();
			}
		},

		// 关闭微信页面
		weixin_closePage() {
			WeixinJSBridge.call('closeWindow');
		},

		// 提交更新的时间
		submitUpdateTime() {
			let send = {
				billId: this.billId,
				enableDate: this.updateTime.start,
				disEnableDate: this.updateTime.end,
			};
			updateInsureRange(send).then(res => {
				console.log(res);
				location.reload();
			});
		},

		// 计算更新时间 type-是否即时起保  start-原起保时间 days-保障期限（天）
		getUpdateTime(type, start, days) {
			let result = {
				start: '',
				end: '',
			};
			if (type) {
				// 即时起保，起保时间是10分钟之后
				result.start = this.$base.getDateTime(Date.now() + 10 * 60 * 1000);
				result.end = this.$base.submitDateFormatter(Date.now() + 10 * 60 * 1000 + Number(days) * 24 * 60 * 60 * 1000, 2);
			} else {
				// 非即时起保，起保时间是下一天0点
				result.start = this.$base.submitDateFormatter(Date.now() + 1 * 24 * 60 * 60 * 1000, 1);
				result.end = this.$base.submitDateFormatter(Date.now() + 10 * 60 * 1000 + Number(days) * 24 * 60 * 60 * 1000, 2);
			}
			return result;
		},

		// 检测订单是否可以支付
		checkOrder(id) {
			return new Promise(resolve => {
				getBillsInfo(id).then(res => {
					let states = res.list.map(bill => bill.billInfo.billState);
					if (states.every(item => item == 2)) {
						// 待支付
						resolve();
					} else {
						if (states.every(item => item == 3) || states.every(item => item == 4)) {
							// 该订单已经支付
							this.disabledPayText = '该订单已经支付，无需再支付';
							this.disabledPayPage = true;
							return;
						}

						// 只有单个订单支付，且是未支付的失效单才能更新 payLimitTime是当天支付限制时间
						if (states.length === 1 && res.list[0].billInfo.payState == 0 && states[0] == 9 && Date.now() >= this.$base.getDateObject(res.list[0].billInfo.payLimitTime).getTime()) {
							// 该订单已经超出最晚支付时间
							let singleBill = res.list[0];
							this.billGoodsName = singleBill.billInfo.insProductName;
							this.billInfo.enableDate = singleBill.billInfo.enableDate;
							this.billInfo.disEnableDate = singleBill.billInfo.disEnableDate;
							this.assured = singleBill.billAssuredList[0].name + (singleBill.billAssuredList.length > 1 ? `（等${singleBill.billAssuredList.length}人）` : '');
							this.updateTime = this.getUpdateTime(singleBill.billInfo.immediatelyFlag, singleBill.billInfo.enableDate, singleBill.billInfo.insuredDateRange);
							this.updatePage = true;
							return;
						}

						// 批量支付
						if (states.length > 1) {
							if (states.some(item => item == 2)) {
								this.disabledPayText = '批量支付订单中有不可支付订单，请使用单独支付，不要使用批量支付';
								this.disabledPayPage = true;
								return;
							}
							this.disabledPayText = '批量支付异常，请使用单独支付';
							this.disabledPayPage = true;
							return;
						}

						// 该订单无法支付
						this.disabledPayText = '订单异常，无法支付，请重新投保';
						this.disabledPayPage = true;
					}
				});
			});
		},

		// 检测环境，进入不同的支付流程
		checkEnvironment(hasCode = false) {
			switch (this.env) {
				case 'wx':
					// 微信环境,需要获取一系列参数，然后重定向回来才能开始支付
					this.entryWXPay(hasCode);
					break;

				case 'zfb':
					// 支付宝环境，什么也不做
					break;

				default:
					break;
			}
		},

		// 登录
		login() {
			return new Promise(resolve => {
				loginNoPwd(this.$route.query.pay).then(res => {
					resolve();
				});
			});
		},

		/**
		 * 微信JSAPI支付流程
		 * 1. 登录（登录后才能调用接口）
		 * 2. 通过接口获取公众号参数，从中拿到appId
		 * 3. 拿着appId，去公众号换取code，页面会带着code重定向回来
		 * 4. 拿着code，通过接口请求openId
		 * 5. 获取支付订单金额
		 * 6. 用户点击支付按钮
		 * 7. 拿着订单id与openId通过接口获取JSAPI支付信息
		 * 8. 调用JSAPI支付方法
		 * 9. 支付成功
		 */

		// 进入微信支付环境
		entryWXPay(hasCode = false) {
			if (!hasCode) {
				this.login().then(() => {
					this.getWxInfo().then(({ appId, appSecret }) => {
						this.getWxCode(appId, appSecret);
					});
				});
			} else {
				// 重定向回来了
				let params = this.$route.query;
				this.getOpenId(params.code).then(() => {
					this.payBtnShow = true;
				});
			}
		},

		// 2. 获取微信公众号参数,接口会返回{appid, appSecret}
		getWxInfo() {
			return new Promise(resolve => {
				getWxInfo().then(res => {
					resolve(res);
				});
			});
		},

		// 3. 拿着appId，去公众号换取code
		getWxCode(appId) {
			let redictUrl = encodeURIComponent(window.location.href);
			let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${redictUrl}&response_type=code&scope=snsapi_base#wechat_redirect`;
			window.location.replace(url);
		},

		// 4. 拿着code，通过接口请求openId
		getOpenId(code) {
			return new Promise(resolve => {
				getOpenId(code).then(res => {
					this.openId = res.openid;
					resolve();
				});
			});
		},

		// 6. 用户点击支付按钮
		wxPay() {
			this.getJSAPI().then(res => {
				// package是个关键字,改一下
				res.wxpackage = res.package;
				this.onBridgeReady(res);
			});
		},

		//  7. 拿着订单id与openId通过接口获取JSAPI支付信息
		getJSAPI() {
			return new Promise(resolve => {
				if (this.isRecharge) {
					// 充值支付
					getWxJSAPIRechargePay(this.rechargeId, this.openId)
						.then(res => {
							console.log(res);
							resolve(res);
						})
						.catch(() => {
							this.loading = false;
						});
				} else {
					// 投保支付
					getWxJSAPIPay(this.billId, this.openId)
						.then(res => {
							resolve(res.brandWCPayRequest);
						})
						.catch(() => {
							this.loading = false;
						});
				}
			});
		},

		// 8. 调用JSAPI支付方法，这一步有点慢
		onBridgeReady({ appId, timeStamp, nonceStr, wxpackage, signType, paySign }) {
			let _this = this;
			let send = {
				appId: appId,
				timeStamp: timeStamp,
				nonceStr: nonceStr,
				package: wxpackage,
				signType: signType,
				paySign: paySign,
			};
			WeixinJSBridge.invoke('getBrandWCPayRequest', send, function (res) {
				if (res.err_msg == 'get_brand_wcpay_request:ok') {
					// 使用以上方式判断前端返回,微信团队郑重提示：
					//res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
					_this.loading = false;

					_this.payPage = false;
					_this.successPage = true;
				} else {
					_this.loading = false;
				}
			});
		},

		// 支付宝支付
		aLiPay() {
			let ids = this.billId;
			let send = {
				billIdList: ids.includes(',') ? ids.split(',') : [this.billId],
				returnUrl: `${location.origin}/paySuccess/${this.billId}`,
				// quitUrl: location.href
			};
			http_aLiPay(send)
				.then(res => {
					ap.pushWindow({
						url: `${location.origin}/aLiPay`,
						data: {
							content: res,
						},
					});
				})
				.finally(() => {
					this.loading = false;
				});
		},

		// 充值支付宝支付
		rechargeLiPay(){
			let send = {
				orderId: this.rechargeId,
				returnUrl: `${location.origin}/paySuccess/${this.rechargeId}`,
			};
			http_getRechargeForm(send)
				.then(res => {
					ap.pushWindow({
						url: `${location.origin}/aLiPay`,
						data: {
							content: res,
						},
					});
				})
				.finally(() => {
					this.loading = false;
				});
		},

		// 开始支付
		pay() {
			this.loading = true;
			switch (this.env) {
				case 'wx':
					// 进入微信支付
					this.wxPay();
					break;

				case 'zfb':
					// 进入支付宝支付
					if(this.isRecharge){
						this.rechargeLiPay()
					}else{
						this.aLiPay();
					}
					break;

				default:
					break;
			}
		},

		// 获取投保订单支付信息
		getBillPayInfo(id) {
			return new Promise(resolve => {
				getPayInfo(id).then(res => {
					this.money = res.totalPermium.toFixed(2);
					this.payPage = true;
					resolve();
				});
			});
		},

		// 获取充值订单支付信息
		getRechargeBillPayInfo(id) {
			return new Promise(resolve => {
				http_getRechargePayInfo(id)
					.then(res => {
						this.money = res.changeAmount.toFixed(2);
						this.payPage = true;
						resolve();
					})
					.catch(() => {
						this.errorPage = true;
					});
			});
		},
	},
};
</script>
